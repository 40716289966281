import React, { useEffect, forwardRef, useImperativeHandle, useRef } from 'react';

import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { createTicketSellClientSecret, fetchGraphQl, updateTicketSellClientSecret } from '../../utils';
import { loadStripe, Stripe, StripeElements } from '@stripe/stripe-js';


const stripePromise = loadStripe( process.env.REACT_APP_STRIPE as string );


export type FormRef = {
    stripe: () => Stripe | null;
    elements: () => StripeElements | null;
    update: () => Promise<void>;
}

const PaymentForm = forwardRef<FormRef>( ( props, ref ) => {
    const stripe = useStripe();
    const elements = useElements();

    useImperativeHandle( ref, () => ({
        stripe: () => stripe,
        elements: () => elements,
        update: async () => {
            await elements?.fetchUpdates()
        }
    }));

    return ( <PaymentElement /> );
})

type Props = {
    clickCount: number;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    eventId: string;
    userToken: string;
    error: string;
    setError: React.Dispatch<React.SetStateAction<string>>;
    ticketBuy: { id: string, quantity: number }[];
    setUpdate: React.Dispatch<React.SetStateAction<boolean>>;
    clientSecret: string;
    paymentIntentId: string;
    setCartId: React.Dispatch<React.SetStateAction<string>>;
    setClientSecret: React.Dispatch<React.SetStateAction<string>>;
    setPaymentIntentId: React.Dispatch<React.SetStateAction<string>>;
}

const PaymentFormContainer= React.forwardRef<FormRef, Props>(({ clickCount, clientSecret, setCartId, setClientSecret, paymentIntentId, setPaymentIntentId, setUpdate, ticketBuy, error, setError, userToken, eventId, loading, setLoading }, ref) => {
    
    const paymentFormRef = useRef<FormRef>(null);

    useImperativeHandle( ref, () => ({
        stripe: () => paymentFormRef.current?.stripe() || null,
        elements: () => paymentFormRef.current?.elements() || null,
        update: async () => {
            await paymentFormRef.current?.update()
        }
    }));
    
    const bootLoader = async () => {
        if ( clientSecret !== '{{CLIENT_SECRET}}' ) return console.log(clientSecret);
        
        setLoading( true );
        const res = await fetchGraphQl(createTicketSellClientSecret, { eventId, userToken, prices: ticketBuy });

        if ( res.errors ) {
            console.log( res.errors );
            setError( res.errors[0].message );
            return;
        }

        setClientSecret( res.data.createTicketSellClientSecret.client_secret );
        setCartId( res.data.createTicketSellClientSecret.cartId );

        setPaymentIntentId( ( await ( await stripePromise )?.retrievePaymentIntent( res.data.createTicketSellClientSecret.client_secret ) )?.paymentIntent?.id || '')

        setLoading( false );
    }

    useEffect( () => {
    
        if ( clickCount === 1 ) bootLoader();

    }, [ ]);

    useEffect(() => {

        let runner = async () => {

            if ( paymentIntentId === "{{PAYMENT_INTENT_ID}}" ) return await bootLoader();

            const res = await fetchGraphQl(updateTicketSellClientSecret, { id: paymentIntentId, eventId, prices: ticketBuy });

            if ( res.errors ) return console.log( res.errors );

            if ( res.data.updateTicketSellClientSecret === 'requires_payment_method' ) {
                setUpdate(true);
            }
        }

        runner();
    }, [ticketBuy]);

    const options = {
        clientSecret,
        layout: 'tabs',
        fields: {
            billingDetails: {
                name: 'auto',
                email: 'auto',
                phone: 'auto',
                address: 'never'
            }
        },
        appearance: {
            theme: 'night',
            labels: 'floating',
            variables: {
                fontFamily: "Expletus Sans"
            }
        }
    } 

    return (
        <div className='check-out-form'>
            <div className='content-container'>
                <div className='slide paymentflow'>
                    {
                        loading || error.length > 0 ? <></> : (
                            <Elements stripe={stripePromise} options={options as any}>
                                <PaymentForm ref={paymentFormRef} />
                            </Elements>
                        )
                    }
                </div>
            </div>
        </div>
    )
})

export default PaymentFormContainer;
