import './index.scss'
import './mobile.scss';

import React, { useState, useEffect } from 'react';

import SignedOutNavBar from "../../../Components/navbar/SignedOut";
import SignedInNavBar from "../../../Components/navbar/SignedIn";

import { EventReviewWelcome } from './EventReviewWelcome';

import { useEventReview } from '../../../hooks';
import { EventReviewForm } from './EventReviewForm';
import { EventReviewConfirm } from './EventReviewConfirm';
import { EventReviewComplete } from './EventReviewComplete';

import { fetchGraphQl, submitTicketReview } from '../../../utils';

const EventReview : React.FC = () => {
    const { loading, user, ticketReview, onChangeName, onChangeDescription, setProfilePicture, onChangeRating, cart_id, setError } = useEventReview();

    const [ slide, setSlide ] = useState<number>( !ticketReview.reviewCompleted ? 0 : 3 );
    const [ dateCompleted, setDateCompleted ] = useState<Date | null>( ticketReview?.dateReviewCompleted ? new Date( ticketReview?.dateReviewCompleted ) : null );

    const eventReviewForm = {
        name: ticketReview.ratingName,
        description: ticketReview.description,
        rating: ticketReview.rating
    };

    const back = () => setSlide( prev => prev - 1 > 0 ? prev - 1 : 0 );
    const forward = () => setSlide( prev => prev + 1 < 3 ? prev + 1 : 3 );

    const submit = async () => {
        let res = await fetchGraphQl( submitTicketReview, { cart_id: cart_id, args: { ...eventReviewForm, photo: ticketReview.photo } });

        if ( res.errors ) {
            console.log( res.errors );
            setError( res.errors[0].message );
        }

        else {
            setDateCompleted( new Date() );
            forward()
        };
    }

    useEffect( () => {
        if ( ticketReview.reviewCompleted ) setSlide( 3 );
    }, [ ticketReview ])

    return (
        <div className='event-review'>
            {
                user ? <SignedInNavBar profilePicture={user.profilePicture} /> 
                    : <SignedOutNavBar />
            }

            { 
                slide === 0 ? 
                    <EventReviewWelcome back={back} forward={forward} banner={ticketReview?.eventBanner!} title={ticketReview?.eventTitle!} /> : 
                        slide === 1 ? 
                            <EventReviewForm back={back} forward={forward} onChangeName={onChangeName} onChangeDescription={onChangeDescription} onChangeRating={onChangeRating} {...eventReviewForm}  /> : 
                                slide === 2 ? <EventReviewConfirm back={back} forward={submit} {...eventReviewForm} banner={ticketReview?.eventBanner!} cart_id={cart_id!} profilePicture={ticketReview.photo} setProfilePicture={setProfilePicture} /> : 
                                    <EventReviewComplete back={ () => setSlide( 1 ) } { ...eventReviewForm } photo={ ticketReview.photo as string } dateCompleted={dateCompleted} />
            }
        </div>
    )
}

export default EventReview;
