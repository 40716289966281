import React, { useState } from 'react';
import Logo from '../../../Components/Logo';

import RatingFC from 'react-star-rating-component';

import { EventWrapperProps } from './wrapper';

type EventReviewFormProp = {
    name: string;
    onChangeName: ( newVal: string ) => void;

    description: string;
    onChangeDescription: ( newVal: string ) => void;

    rating: number;
    onChangeRating: ( newVal: number ) => void;
}

const Rating: any = RatingFC;

export const EventReviewForm: React.FC< EventWrapperProps & EventReviewFormProp > = ({ back, forward, name, onChangeName, description, onChangeDescription, rating, onChangeRating }) => {
    const [ nameError, setNameError ] = useState<string>('');
    const [ descError, setDescError ] = useState<string>('');
    
    const verify = () => {
        if ( name.length > 0 ) {
            if ( description.length > 0 ) return true;
            else setDescError('Please provide a desc');
        }else setNameError('Please provide a name');

        return false;
    }

    console.log( name, description, rating );

    return (
        <div className='event-wrapper'>
            <Logo />

            <div className='logo ticket-review'>Ticket Review</div>

            <input className='form-control name' placeholder='Name' type='text' onChange={ ( e ) => onChangeName( e.target.value ) } value={name} />

            <div className='star-wrapper'>
                <Rating
                    name='rate'
                    editing={true}
                    value={rating}
                    onStarClick={ ( nextValue: number ) => onChangeRating( nextValue ) }
                />
            </div>

            <textarea className='form-control desc' placeholder='Description' onChange={ ( e ) => onChangeDescription( e.target.value ) } value={description}/>

            <div className='button-wrapper'>
                <div className='button center' onClick={back} >Back</div>
                <div className='button center' onClick={ () => verify() && forward() } >Continue</div>
            </div>
        </div>
    )
}