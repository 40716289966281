import React from "react";

type Props = {
    updateUserInfo: React.Dispatch<React.SetStateAction<{
        name: string;
        email: string;
        phoneNumber: string;
    }>>;

    userInfo: {
        name: string;
        email: string;
        phoneNumber: string;
    }
}

const Contact: React.FC<Props> = ({ userInfo, updateUserInfo }) => {

    return (
        <div className="check-out-form">
            <div className="content-container">
                <div
                    className="slide"
                >
                    <div className="content">
                        <div className='form-group'>
                            <input
                                type='text' placeholder='Name'
                                value={userInfo.name}
                                onChange={(e) => updateUserInfo(prev => ({ ...prev, name: e.target.value }))}
                            />

                            <input
                                type='email' placeholder='Email'
                                value={userInfo.email}
                                onChange={(e) => updateUserInfo(prev => ({ ...prev, email: e.target.value }))}
                            />

                            <input
                                type='telephone' placeholder='Phone Number'
                                value={userInfo.phoneNumber}
                                onChange={(e) => updateUserInfo(prev => ({ ...prev, phoneNumber: e.target.value }))}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;
