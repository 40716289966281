import React, { useState, useRef } from 'react';

import Logo from '../../../Components/Logo';

import RatingFC from 'react-star-rating-component';

import Markdown from 'markdown-to-jsx';

import { truncateText, getReviewProfileUploadApi } from '../../../utils';

import { EventWrapperProps } from './wrapper';

type EventConfirmProps = {
    banner: string;
    name: string;
    description: string;
    rating: number;
    cart_id: string;
    profilePicture: string | null;
    setProfilePicture: ( val: string ) => void;
}

const Rating: any = RatingFC;

export const EventReviewConfirm: React.FC<EventWrapperProps & EventConfirmProps> = ({ back, forward, banner, name, rating, description, cart_id, profilePicture, setProfilePicture }) => {
    const imageInputRef = useRef<HTMLInputElement>(null);

    const handleInputImageChange = async () => {
        if ( imageInputRef.current ) {
            const form = new FormData();
            const image = imageInputRef.current.files![0];

            if (image) {
                form.append("image", image);
                form.append("cart_id", cart_id);

                const req = await fetch(getReviewProfileUploadApi(), {
                    method: "POST",
                    body: form
                });

                let result = await req.json();

                if ( result.error ) {
                    console.log('Error uploading: ' + result.error );
                }

                if ( result.url ) {
                    setProfilePicture(result.url);
                }
            }
        }
    }

    return (
        <div className='event-wrapper confirm'>
            <Logo />
            
            <div className='logo ticket-review'>Ticket Review</div>
            
            <div className='banner-wrapper'>
                <img src={ !profilePicture ? banner : profilePicture } alt='banner' className='banner' />
                <div className='upload-card' onClick={ () => { imageInputRef.current?.click() } }>Upload Picture</div>
                <input type="file" accept=".png, .jpeg, .jpg, .svg" ref={imageInputRef} name="image" style={{display: "none" }} onChange={handleInputImageChange} title="imageInput"/>
            </div>

            <div className='title'>Name: {name}</div>

            <div className='star-wrapper'>
                <Rating
                    name='rate'
                    editing={false}
                    value={rating}
                />
            </div>

            <div className='desc'>
                <Markdown>{ truncateText(description, 120 ) }</Markdown>
            </div>

            <div className='button-wrapper'>
                <div className='button center' onClick={back} >Back</div>
                <div className='button center' onClick={ () => forward() } >Submit</div>
            </div>
        </div>
    )
}