import React from "react";

type Props = {
  checkOut: {
    quantity: number;
    title: string | undefined;
    price: number | undefined;
  }[];
};

const CheckoutList: React.FC<Props> = ({ checkOut }) => {

  const subTotal = checkOut.reduce((acc, curr) => acc + (curr.price || 0) * curr.quantity, 0);

  return (
    <div className="check-out-form">
      <div className="content-container">
        <div
          className="slide"
        >
          <div className="content checkout">
            {checkOut.map((cItem, i) => (
              <div className="content-item" key={i}>
                <div className='title'>{cItem.title}</div>
                <div className='price'>${cItem.price} * {cItem.quantity}</div>
                <div className='total'>${ ( cItem.price || 0 )* cItem.quantity}</div>
              </div>
            ))}

            <div className='content-item'>
                <div className='title'>SubTotal</div>
                <div className='price'></div>
                <div className='total'>${subTotal}</div>
            </div>

            <div className='content-item'>
                <div className='title'>Total</div>
                <div className='price'></div>
                <div className='total'>${subTotal}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutList;
