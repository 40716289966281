import React from 'react';
import { EventPricesResponse } from '../../utils';

type Props = {
    tickets: EventPricesResponse[];
    ticketBuy: { id: string; quantity: number }[];
    setTicketBuy: React.Dispatch<React.SetStateAction<{
        id: string;
        quantity: number;
    }[]>>;
}

const TicketCart: React.FC<Props> = ({ tickets, ticketBuy, setTicketBuy }) => {

    const subtractQuantity = ( id: string ) => {
        if ( ticketBuy.findIndex( p => p.id === id ) !== -1 ) {
            setTicketBuy( prevState => {
                return prevState.map( ticket => {
                    if ( ticket.id === id && ticket.quantity > 0 ) {
                        return { ...ticket, quantity: ticket.quantity - 1 }
                    }
    
                    return ticket;
                })
            });
        }else {
            setTicketBuy( prev => ([...prev, { id, quantity: 0 } ] ) );
        }
    }

    const addQuantity = ( id: string ) => {
        if ( ticketBuy.findIndex( p => p.id === id ) !== -1 ) {
            let foundTicket = tickets.findIndex( p => p.id === id );

            setTicketBuy( prevState => {
                return prevState.map( ticket => {
                    if ( ticket.id === id && ticket.quantity < tickets[ foundTicket ].ticketAvailable) {
                        return { ...ticket, quantity: ticket.quantity + 1 }
                    }
    
                    return ticket;
                })
            });
        }else {
            setTicketBuy( prev => ([...prev, { id, quantity: 1 } ] ) );
        }
    }

    const getQuantity = ( id : string ) => {
        const ticket = ticketBuy.find( ticket => ticket.id === id );

        return ticket ? ticket.quantity : 0;
    }

    return (
        <div className="tickets-container">
            <div className='padding' />
            {
                tickets.map((p, index) => (
                    <div className='ticket' key={`ticket-${index}`}>
                        <div className='title-amount'>
                            <div className='title-count'>
                                <div className='title'>{p.title}</div>
                                <div className='count'>
                                    <div className='minus' onClick={() => subtractQuantity(p.id)}>-</div>
                                    <div className='num'>{getQuantity(p.id)}</div>
                                    <div className='plus' onClick={() => addQuantity(p.id)}>+</div>
                                </div>
                            </div>

                            <div className='cost-limit'>
                                <div className='cost'>${p.amount}</div>
                                {
                                    p.ticketAvailable > 0 ? <div className='stats'>{p.ticketAvailable} Tickets Available</div>
                                        : <div className='stats'>SOLD OUT</div>
                                }
                            </div>

                            <div className='seperator' />
                        </div>

                        <div className='desc'>{p.description}</div>
                    </div>
                ))
            }

        </div>
    )
}

export default TicketCart;
