import React, { useState, useEffect, useRef, useImperativeHandle } from "react";

import QRCodeStyling from "qr-code-styling";

type EventQrCodeProps = {
  data: string;
  image: string;
  display: boolean;
  upload?: { link: string, data: { [key: string]: string } };
}

export type EventQrCodeRef = {
  triggerUpload: ( upload: { link: string, data: { [key: string ] : string }}) => Promise<void>;
};

const EventQrCode = React.forwardRef<EventQrCodeRef, EventQrCodeProps>(
  ({ data, image, display, upload }, refForward) => {
    const options = {
      data,
      margin: 4,
      qrOptions: {
        typeNumber: 0,
        mode: "Byte",
        errorCorrectionLevel: "H",
      },
      imageOptions: { hideBackgroundDots: true, imageSize: 0.9, margin: 0 },
      dotsOptions: {
        type: "extra-rounded",
        color: "#f5f5f5",
        gradient: {
          type: "linear",
          rotation: 0.10471975511965977,
          colorStops: [
            { offset: 0, color: "#b10b0b" },
            { offset: 1, color: "#8e0693" },
          ],
        },
      },
      backgroundOptions: { color: "#ffffff", gradient: undefined },
      image,
    };

    const qrCodeRef = useRef<HTMLDivElement>(null);
    const [qrCode] = useState<QRCodeStyling>(new QRCodeStyling(options as any));

    useEffect(() => {
      if (qrCodeRef.current) {
        qrCode.append(qrCodeRef.current);
      }
    }, [qrCode]);

    useEffect(() => {

      if (!upload) return;

      uploadQrCode(upload);
    }, [data, image, upload, qrCode]);

    // Function to upload QR code
    const uploadQrCode = async (upload: {
      link: string;
      data: {
        [key: string]: string;
      };
    }) => {
      if (!upload) return;
      const blob = await qrCode.getRawData("png");

      if (blob) {
        const form = new FormData();
        form.append("image", blob as Blob);

        Object.keys(upload.data).forEach((key) => {
          form.append(key, upload.data[key]);
        });

        await fetch(upload.link, {
          method: "POST",
          body: form,
        });
      }
    };

    // Expose function to parent via useImperativeHandle
    useImperativeHandle(refForward, () => ({
      triggerUpload: async ( upload: { link: string, data: { [key: string ] : string }}) => await uploadQrCode(upload)
    }));

    return (
      <div className="qr-code-container" style={{ display: display ? undefined : "none" }}>
        <div ref={qrCodeRef} />
      </div>
    );
  }
);

export default EventQrCode;
