import './index.scss';
import './mobile.scss';

import React, { useState, useEffect, useRef } from 'react';
import { createTicketSellClientSecret, EventPricesResponse, fetchGraphQl, getDomainUrl, getServerUrl, registerFreeEventTickets } from '../../utils';
import TicketContainer from './TicketContainer';
import CheckoutList from './CheckOut';
import Contact from './Contact';
import PaymentFormContainer, { FormRef } from './Payment';
import EventQrCode, { EventQrCodeRef } from '../EventQrCode';

type PurchaseEventTicketProps = {
    tickets: EventPricesResponse[];
    eventId: string;
    userToken?: string;
    active: boolean;
    clickCount: number;
    ticketType: 'infinite' | 'limited';
    disable: () => void;
}


export const PurchaseEventTicket: React.FC<PurchaseEventTicketProps> = ({ active, disable, tickets, eventId, clickCount, userToken }) => {
    const paymentFormRef = useRef<FormRef>(null);
    
    const [ loading, setLoading ] = useState<boolean>(true);
    const [ error, setError ] = useState<string>('');

    const [ slideView, setSlideView ] = useState<"tickets" | "checkout" | "contact" | "payment">('tickets');

    const [ ticketBuy, setTicketBuy ] = useState<{ id: string; quantity: number }[]>([]);

    const [ userInfo, setUserInfo ] = useState<{ name: string, email: string, phoneNumber: string }>({ name: '', email: '', phoneNumber: '' });
    
    const [ clientSecret, setClientSecret ] = useState<string>('{{CLIENT_SECRET}}');
    const [ paymentIntentId, setPaymentIntentId ] = useState<string>('{{PAYMENT_INTENT_ID}}');
    const [ cartId, setCartId ] = useState<string>('{{TICKET_CART_ID}}');

    const [ update, setUpdate ] = useState<boolean>( false );

    const qrCodeRef = useRef<EventQrCodeRef>(null);

    const back = () => {
        if ( slideView === 'checkout' ) setSlideView('tickets');
        
        if ( slideView === 'contact' ) setSlideView('checkout');

        if ( slideView === 'payment' ) setSlideView('contact');
    }

    const forward = () => {
        if ( slideView === 'tickets' ) {
            if ( ticketBuy.length === 0 ) return setError('Please select tickets');

            setSlideView('checkout');
        }else if ( slideView === 'checkout' ) setSlideView('contact');
        else if ( slideView === 'contact' ) {
            if ( userInfo.email.length === 0 || userInfo.phoneNumber.length === 0 ) return setError('Please provide valid contact details');

            setSlideView('payment');
        }
    }

    const register = async () => {
        // Use to register free event tickets total
        console.log('register free event tickets total');

        setLoading( true );

        let secretRes = await fetchGraphQl( createTicketSellClientSecret, { eventId, userToken, prices: ticketBuy } );

        if ( secretRes.errors ) {
            console.log( secretRes.errors );
            setError( secretRes.errors[0].message );
            return;
        }

        setCartId( secretRes.data.createTicketSellClientSecret.cartId );

        if ( !qrCodeRef.current ) return;

        await qrCodeRef.current.triggerUpload({ link: `${getServerUrl()}api/cart/QrCode`, data: { cart_id: secretRes.data.createTicketSellClientSecret.cartId } })
        
        let res = await fetchGraphQl( registerFreeEventTickets, { args: {
            eventId,
            userToken,
            cartId: secretRes.data.createTicketSellClientSecret.cartId,
            email: userInfo.email,
            phoneNumber: userInfo.phoneNumber,
            name: userInfo.name,
            tickets: ticketBuy
        } });

        if ( res.errors ) {
            console.log( res.errors );
            setError( res.errors[0].message || '' );
        }

        setLoading( false );
        setCartId( res.data.registerFreeEventTickets );

        window.location.href = `${getDomainUrl()}events/${eventId}/ticket?cart_id=${secretRes.data.createTicketSellClientSecret.cartId}`;
    }

    const buyTicket = async () => {
        // for none free tickets total

        let { stripe: stripeF, elements: elementsF } =  paymentFormRef.current || {};

        let stripe = stripeF ? stripeF() : null;
        let elements = elementsF ? elementsF() : null;

        if ( !stripe || !elements ) return;

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${getDomainUrl()}events/${eventId}/ticket?cart_id=${cartId}`,
                payment_method_data: {
                    billing_details: {
                        email: userInfo.email,
                        name: userInfo.name,
                        phone: userInfo.phoneNumber
                    }
                },
                
            },
            redirect: 'always'
        });

        if ( error ) {
            setError( error.message || '');
            console.log(error);
            return;
        }
    }

    const getCheckOut = () => {
        return ticketBuy.map( tB => {
            const ticket = tickets.find( t => t.id === tB.id );

            return { quantity: tB.quantity, title: ticket?.title, price: ticket?.amount }
        })
    }

    useEffect( () => {
        if ( update ) {
            (
                async () => {
                    const updated = await paymentFormRef.current?.update();
                    setUpdate(false);
                }
            )()
        }
    }, [ update ])

    return (
        <div className='purchase-event-ticket' style={{ display: active ? undefined : 'none' }}>
            <div className='shade' onClick={ () => disable() } />

            <div className='check-out'>
                
                {
                    slideView === 'tickets' ?
                        <TicketContainer tickets={tickets} ticketBuy={ticketBuy} setTicketBuy={setTicketBuy} />
                            : slideView === "checkout" ? <CheckoutList checkOut={getCheckOut()} />
                                : slideView === "contact"? <Contact userInfo={userInfo} updateUserInfo={setUserInfo} />
                                : <PaymentFormContainer ref={paymentFormRef}
                                    clickCount={clickCount} loading={loading} setLoading={setLoading} 
                                    eventId={eventId} userToken={userToken || ''} error={error} setError={setError} 
                                    ticketBuy={ticketBuy} setUpdate={setUpdate} 
                                    clientSecret={clientSecret} paymentIntentId={paymentIntentId}
                                    setCartId={setCartId} 
                                    setClientSecret={setClientSecret} setPaymentIntentId={setPaymentIntentId} 
                                />
                }

                <div className='next-buttons-container'>

                    {
                        slideView !== 'tickets' && <div className='mobile-next-button back' onClick={ back }>BACK</div>
                    }

                    {
                        slideView === 'contact' && getCheckOut().reduce( ( a, curr ) =>  a + curr.quantity * ( curr.price || 0 ), 0) === 0 ?
                            <div className='mobile-next-button' onClick={ register }>Register</div>
                            : slideView !== 'payment' ? <div className='mobile-next-button' onClick={ forward }>NEXT</div>
                                : <div className='mobile-next-button' onClick={ buyTicket }>Buy Tickets</div>

                    }
                </div>
            </div>

            <EventQrCode ref={qrCodeRef} data={`${getDomainUrl()}events/${eventId}/ticket?cart_id=${cartId}`} image="/logo-text.png" display={true} upload={{ link: `${getServerUrl()}api/cart/QrCode`, data: { cart_id: cartId } }} />
        </div>
    )
}
